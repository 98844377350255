import axios from 'axios'

import pagina from "@/class/pagina";

export class Servidores {
  static async obtemTodos () {
    return axios.get('/servidores')
  }
  static async obtemTodosTipo (tipo,pg) {
    const routePg = pagina.routePg;
    //pagina.routePg = ""
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('/servidores/tipo/'+tipo+'/ano/'+ano+'?page='+pg+routePg);

  }
  static async obtemUm (id) {
    return axios.get('/servidores/'+id)
  }
  static async buscarServidorIdLogin (login_id) {
    return axios.get('servidores/login/'+login_id)
  }
  static async cadastrar (payload) {
    return axios.post('/servidores', payload)
  }
  static async alterar (payload) {
    return axios.put('/servidores/'+payload.id, payload)
  }
  static async alterarLogin (payload) {
    return axios.put('/servidores/'+payload.id+'/login', payload)
  }
  static async deletarLogin (payload) {
    return axios.delete('/servidores/'+payload.id+'/login', payload)
  }
  static async removerServidor (id) {
    return axios.delete('/servidores/'+id+'/semlogin')
  }
  static async remover (id) {
    return axios.delete('/servidores/'+id)
  }
  static async addEspecProfessor (payload) {
    return axios.post('/servidores/especialidade', payload)
  }
  static async visualizarEspecializaoProf (servidor_id) {
    return axios.get('servidores/especialidade/all?servidor_id='+servidor_id);
  }
  static async alterarEspecializacao (payload) {
    return axios.put('/servidores/especialidade/'+payload.id, payload)
  }
  static async deletarEspecializacao (id) {
    return axios.delete('servidores/especialidade/'+id);
  }
  static async buscarProfessoresNaoHabilitados () {
    return axios.get('servidores/naohabilitado');
  }
  static async pesqServidorCompleto (payload) {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('/servidores/pesquisa/'+payload.nome+'/completa/funcao/'+payload.funcao+'/'+ano)
  }
  static async disciplinasRelacionadas (payload) {
    return axios.get('servidores/disciplinas/relacionadas?escola_id='+payload.escola_id+'&segmento_id='+payload.segmento_id+'&turno='+payload.turno+'&disciplina_id='+payload.disciplina_id+'&ano='+payload.ano);
  }

  static async ProfessorPrincipalShow (playload) {
    return axios.post('/prof-principal-show', playload)
  }

  static async ProfessorPrincipalStore (playload) {
    return axios.post('/prof-principal', playload)
  }

  static async ProfessorPrincipalStore (id) {
    return axios.delete('/prof-principal/'+id)
  }
}
